const sha256 = require("js-sha256").sha256; //引入sha256库
export function sort_ASCII (obj) {
    var arr = new Array();
    var num = 0;
    for (var i in obj) {
        arr[num] = i;
        num++;
    }

    var sortArr = arr.sort();
    var sortObj = {};

    for (var i in sortArr) {
        if (typeof obj[sortArr[i]] != "object") {
            //普通数据类型
            sortObj[sortArr[i]] = obj[sortArr[i]];
        } else {
            // 如果是数组
            if (Array.isArray(obj[sortArr[i]])) {
                if (!sortObj[sortArr[i]]) {
                    sortObj[sortArr[i]] = [];
                }

                obj[sortArr[i]].forEach((element, index, arr) => {
                    sortObj[sortArr[i]].push(sort_ASCII(element));
                });
            }
        }
    }
    return sortObj;
}

export const signStr = (data) => {
    // 签名格式：data(字典升序)
    let ret = [];
    for (let it in data) {
        let val = data[it];
        if (typeof val === 'object' && //
            (!(val instanceof Array) || (val.length > 0 && (typeof val[0] === 'object')))) {
            val = JSON.stringify(val);
        }
        ret.push(it + '=' + val);
    }
    // 字典升序
    ret.sort();
    let signsrc = ret.join('&');
    // return signsrc
    return sha256(signsrc + '3a91ca99a9497bd1b5edd352e78bd7a5');
};