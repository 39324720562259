<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="course-detail-box">
        <div class="centre-content">
          <div class="course-detail">
            <p class="course-cover"><img :src="courseInfo.coverImg" /></p>
            <div class="course-info">
              <p class="course-title">{{ courseInfo.courseTitle }}</p>
              <p class="course-name">{{ courseInfo.courseLecturer }}</p>
              <p class="course-intro">
                {{ courseInfo.courseSummary }}
                <!-- <p v-html="courseInfo.courseDesc"></p> -->
              </p>
              <!-- 富文本 -->
              <!-- <p v-html="artInfo.artBody"></p> -->
              <div class="watchBtn hoverFinger" @click="toCoursePlay(startClassItem)">
                课程观看
              </div>
              <div class="cover-info">
                <div class="flex-box">
                  <p class="learnNum-img">
                    <img src="@/assets/home/learn-num2.png" alt="" />
                  </p>
                  <span>{{ courseInfo.studyNum }}</span>
                </div>
                <div class="flex-box marLeft-16">
                  <p class="shareNum-img">
                    <img src="@/assets/home/share-num2.png" alt="" />
                  </p>
                  <span>{{ courseInfo.shareNum }}</span>
                </div>
                <div class="share-box">
                  <span class="share hoverFinger" @click="share()">分享</span>
                  <span style="color: red">*</span>
                  <span class="share-text">点击分享生成二维码</span>
                </div>
              </div>
            </div>
          </div>
          <div class="course-detail-introduce">
            <!-- 左边详情介绍部分 -->
            <div class="course-detail-left">
              <tabDetails ref="tabDetails" :courseid="courseid" :courseDetail="courseDetail"
                @toCoursePlay="toCoursePlay" />
            </div>

            <!-- 教师团队 -->
            <div class="course-detail-right">
              <div class="teacher-list">
                <h3>教师团队</h3>
                <div class="teacher-item" v-for="courseLectorsItem in courseLectors" :key="courseLectorsItem.id">
                  <div class="flex-box marbottom-16">
                    <p class="teacher-cover">
                      <img :src="courseLectorsItem.lectorAvatar" alt="" />
                    </p>
                    <p class="teacher-name">
                      {{ courseLectorsItem.lectorName }}
                    </p>
                  </div>
                  <p>
                    {{ courseLectorsItem.lectorDesc }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 相关推荐 -->
          <div class="course-recommend">
            <div class="flexBox-space-between">
              <h2>相关推荐</h2>
              <div class="block">
                <!-- <el-pagination layout="prev, pager, next" :total="30">
                </el-pagination> -->
                <el-pagination :page-size="4" background layout="prev, pager, next" :total="total" pager-count:7
                  @current-change="currentChange" :current-page="currentPage">
                </el-pagination>
              </div>
            </div>
            <div class="course-list">
              <div class="course-item" v-for="item in courseRecommendList" :key="item.id"
                @click="toDetail(item.courseId)">
                <div class="course-cover">
                  <div class="cover-img">
                    <img :src="item.coverImg" alt="" />
                  </div>
                  <div class="cover-info">
                    <div class="flex-box">
                      <p class="learnNum-img">
                        <img src="@/assets/home/learn-num.png" alt="" />
                      </p>
                      <span>{{ item.studyNum }}</span>
                    </div>
                    <div class="flex-box marLeft-16">
                      <p class="shareNum-img">
                        <img src="@/assets/home/share-num.png" alt="" />
                      </p>
                      <span>{{ item.shareNum }}</span>
                    </div>
                  </div>
                </div>
                <div class="course-info">
                  <p class="course-title">{{ item.courseTitle }}</p>
                  <p class="course-introduce">{{ item.courseLecturer }}</p>
                  <p class="courseHover">
                    <img src="@/assets/home/course-hover-icon.png" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="" :visible.sync="shareDialogVisible" width="22%" center>
      <p class="qrCode"><img :src="qrcode" alt="" /></p>
      <span slot="footer" class="dialog-footer">
        <p class="download-btn hoverFinger" @click="downLoad()">保存二维码</p>
        <!-- <el-button @click="shareDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="shareDialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
    <!-- <shareDialog :visible.sync="shareDialogVisible" /> -->
  </div>
</template>

<script>
import request from "@/utils/request";
import tabDetails from "./components/tabDetails.vue";
import shareDialog from "@/components/shareDialog.vue";
import { resStudyRecordsDianji } from '@/api/video.js';

export default {
  data () {
    return {
      type: 1, // 1 课程介绍 2 章节目录 3 课程素材
      courseid: "", //课程id
      courseInfo: {}, //课程信息
      courseRecommendList: [], //课程推荐列表
      total: 0,
      currentPage: 1, // 当前页码
      courseLectors: [], //讲师
      courseLessons: [], //章节目录
      courseLessonType: "", //  1 无章节 2 有章无节 3 有章有节有课时
      sucaiTotal: "",

      isCollapse: false,
      startClassItem: {}, // 第一个课时
      courseDetail: {},
      qrcode: "", //课程分享二维码
      qrcodeUrl: "", //下载图片
      shareDialogVisible: false,
    };
  },
  async mounted () {
    this.courseid = this.$route.params.courseid;
    await this.getCourseDetail();
    await this.getCourseRecommend(); //课程推荐
  },
  //事件方法
  methods: {
    currentChange (val) {
      this.currentPage = val;
      this.getCourseRecommend();
    },
    typeChange (type) {
      this.type = type;
    },
    async resStudyRecordsDianji (courseId) {
      await resStudyRecordsDianji({ cid: courseId, resType: 1, clientType: 3 })
    },
    getCourseDetail () {
      let data = { courseId: this.courseid };
      this.resStudyRecordsDianji(data.courseId)
      request({
        url: "/course/detail",
        method: "post",
        data,
      })
        .then((res) => {
          this.courseInfo = res.data.courseInfo; //课程信息
          this.courseLectors = res.data.courseLectors; //课程老师
          this.courseLessons = res.data.courseLessons; //课程目录
          this.courseDetail = res.data;
          // this.$set(this, 'courseDetail', res.data)
          // console.log(this.courseDetail, 'courseDetail')
          // this.$refs.tabDetails.init();

          this.startClassItem = this.getStartClassItem(res.data.courseLessons); // 获取第一个课时
          this.courseLessonType = res.data.courseLessonType; //  1 无章节 2 有章无节 3 有章有节有课时
        })
    },
    getCourseRecommend () {
      //课程推荐
      let data = { recommendNum: 10, cateId: 0 };
      request({
        url: "/course/recommend",
        method: "post",
        data,
      })
        .then((res) => {
          this.courseRecommendList = res.data;
          this.total = res.data.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    share () {      // 课程分享弹框
      let data = { shareType: "course", shareResId: this.courseid };
      request({
        url: "/share",
        method: "post",
        data,
      })
        .then((res) => {
          this.shareDialogVisible = true;
          this.qrcode = res.data.qrcode;
          this.qrcodeUrl = decodeURIComponent(res.data.qrcode);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toDetail (courseid) {
      this.courseid = courseid;
      window, scrollTo(0, 0); //跳转到最上面
      this.getCourseDetail();
    },
    toCoursePlay (item) {
      console.log(1111);

      let contentId = item.contentId;
      let courseId = item.courseId;
      this.$router.push({
        path: `/coursePlay/${courseId}?contentId=${contentId}&courseLessonType=${this.courseLessonType}`,
      });
    },
    // 判断是否是课时
    isMenuLink (obj) {
      return "contentId" in obj && "courseId" in obj;
    },
    // 合并章节和课时
    mergeMenu (obj) {
      let list = [];
      if (obj.units instanceof Array) {
        list = list.concat(obj.units);
      }
      if (obj.lessons instanceof Array) {
        list = list.concat(obj.lessons);
      }
      return list;
    },
    // 获取第一个课时
    getStartClassItem (list) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (this.isMenuLink(item)) {
          return item;
        } else {
          return this.getStartClassItem(this.mergeMenu(item));
        }
      }
    },
    downLoad () {
      //下载
      var a = document.createElement("a");
      var body = document.querySelector("body");

      var base64 = this.qrcodeUrl; // imgSrc 就是base64哈
      var byteCharacters = atob(
        base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
      );
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: undefined,
      });

      a.href = URL.createObjectURL(blob);
      a.download = "分享二维码.png";
      a.style.display = "none";
      body.appendChild(a);
      a.click();
      body.removeChild(a);
      window.URL.revokeObjectURL(a.href);

      // this.shareDialogVisible = false;
    },
  },
  //注册子组件
  components: {
    tabDetails,
    shareDialog,
  },
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime (val) { },
  },
};
</script>

<style lang='less' scoped>
h3 {
  font-size: 16px;
  font-weight: bold;
  color: #303133;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  color: #303133;
}

.course-detail-box {
  .centre-content {
    padding-bottom: 110px;

    .course-detail {
      display: flex;
      padding-top: 40px;

      .course-cover>img {
        width: 450px;
        height: 300px;
        background: #d8d8d8;
        border-radius: 8px;
        display: block;
      }

      .course-info {
        margin-left: 50px;
        width: 940px;
        position: relative;

        .course-title {
          margin-bottom: 12px;
          font-size: 16px;
          font-weight: bold;
          color: #3b3d41;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .course-name {
          margin-bottom: 12px;
          font-size: 14px;
          color: #6f7174;
        }

        .course-intro {
          margin-bottom: 12px;
          font-size: 14px;
          line-height: 22px;
          height: 135px;
          color: #6f7174;
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        .watchBtn {
          font-size: 18px;
          color: #ffffff;
          width: 160px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          background: linear-gradient(135deg,
              rgba(0, 140, 255, 0.55) 0%,
              #4676f8 100%);
          box-shadow: 1px 2px 4px 0px rgba(0, 56, 187, 0.13);
          border-radius: 6px;
        }

        .cover-info {
          display: flex;
          position: absolute;
          bottom: 0;
          font-size: 14px;
          color: #6f7174;

          .share-box {
            margin-left: 24px;

            .share {
              font-size: 14px;
              color: #4676f8;
            }

            .share-text {
              font-size: 14px;
              color: #6f7174;
            }
          }
        }
      }
    }

    .course-detail-introduce {
      padding: 50px 0px;
      display: flex;
      justify-content: space-between;

      .course-detail-left {
        width: 900px;
      }

      .course-detail-right {
        width: 490px;
        max-height: 800px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        // overflow-y: scroll;
        overflow-y: auto;

        .teacher-list {
          padding: 16px 24px;
          overflow-y: scroll;
          overflow: hidden;

          .teacher-item {
            margin-top: 16px;

            .teacher-cover>img {
              display: block;
              width: 60px;
              height: 60px;
              background: #d8d8d8;
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
              border-radius: 50%;
              margin-right: 16px;
            }

            .teacher-name {
              font-size: 16px;
              font-weight: bold;
              color: #303133;
            }
          }
        }
      }

      // 滚动条
      .course-detail-right::-webkit-scrollbar {
        overflow-y: scroll;
      }

      ::-webkit-scrollbar-thumb {
        height: 109px;
        background: #dcdfe6;
        border-radius: 12px;
      }
    }

    // 相关推荐
    .course-recommend {
      padding: 24px;
      // width: 1440px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      border-radius: 10px;

      .course-list {
        display: flex;
        padding-top: 24px;
        overflow: hidden;

        .course-item {
          width: 336px;
          height: 310px;
          background: #ffffff;
          box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
          border-radius: 8px;
          // margin-right: 32px;
          margin-right: 16px;
          margin-bottom: 40px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          .course-cover {
            .cover-img>img {
              width: 336px;
              height: 224px;
              border-radius: 8px;
              background-color: #6f7174;
              display: block;
            }

            position: relative;
          }

          .cover-info {
            width: 303px;
            background: linear-gradient(180deg,
                rgba(54, 54, 56, 0) 0%,
                rgba(54, 54, 56, 0.25) 100%);
            border-radius: 0px 0px 8px 8px;
            position: absolute;
            bottom: 0px;
            display: flex;
            padding: 17px 16px 7px;
            font-size: 12px;
            color: #ffffff;
          }

          .course-info {
            padding: 16px;
            position: relative;

            .course-title {
              font-size: 16px;
              color: #3b3d41;
              margin-bottom: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .course-title :hover {
              color: #4676f8;
            }

            .course-introduce {
              font-size: 12px;
              color: #909399;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .courseHover {
              position: absolute;
              right: 0;
              bottom: -8px;
              display: none;
            }

            .courseHover>img {
              width: 49px;
              height: 48px;
            }
          }
        }

        .course-item:hover {
          transform: scale(1.03);
        }

        .course-item:hover .courseHover {
          display: inline-block;
          animation: mymove ease 2s;
          animation-iteration-count: 1;
          /*设置动画播放次数*/
          animation-fill-mode: forwards;
        }

        @keyframes mymove {
          from {
            // top: 80px;
            opacity: 0;
          }

          to {
            // top: 40px;
            opacity: 1;
          }
        }
      }
    }
  }
}

// table 样式
.jieBox {
  border: 10px solid red;
}

/deep/.el-menu--inline,
/deep/.el-submenu,
/deep/.el-menu-item {
  border: 1px solid #dcdfe1 !important;
  border-bottom: 1px solid #dcdfe1 !important;
  margin: -1px -1px -1px -1px !important;
  // border-radius: 6px !important;
}

/deep/.el-submenu__title:hover,
/deep/.el-menu-item:hover {
  background-color: transparent !important;
}

/deep/.el-submenu__title {
  padding-left: 10px !important;
  height: 50px !important;
}

/deep/.el-submenu .el-menu-item {
  padding: 0px 20px !important;
}

/deep/.el-menu-item,
/deep/.is-active {
  color: black !important;
}

// /deep/.el-menu {
//   border-right: none !important;
// }

// 弹框
.el-dialog,
.el-dialog--center {
  border-radius: 20px;

  .qrCode {
    text-align: center;
  }

  .qrCode>img {
    width: 200px;
    height: 200px;
    background-color: #6f7174;
  }

  .download-btn {
    width: 120px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #4676F8;
    font-size: 16px;
    color: #4676F8;
    margin: 0 auto;
  }
}
</style>
