import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import md5 from 'js-md5';
import { sort_ASCII, checkEmpty } from '@/utils';
import sha256 from 'js-sha256';
import { signKey, requestTimeOut, baseUrl } from '@/settings.js';
import Qs from 'qs';
import { localStorageGet } from '@/utils/localStorage';

const service = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
    timeout: requestTimeOut, 
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    transformRequest: [(data) => Qs.stringify(data, { indices: true })],
});

service.interceptors.request.use(
    (config) => {
        //动态生成sign
        if (config.method === 'get') {
            const data = JSON.stringify(sort_ASCII(config.params));
            const key = signKey;
            const originKey = `${data}${key}`;
            const sign = md5(originKey);
            config['sign'] = sign;
        }

        if (config.method === 'post') {
            let params = config.data;
            if (config.url == '/upload') {
            } else {
                params = sort_ASCII(params);
                let perSignStrArr = [];
                for (let key in params) {
                    let aone = key + '=' + params[key];
                    perSignStrArr.push(aone);
                }
                let perSignStr = perSignStrArr.join('&');
                const originKey = `${perSignStr}${signKey}`;
                const sign = sha256(originKey);
                config.data['sign'] = sign;
            }
            let userInfo = localStorageGet('userInfo');
            if (checkEmpty(userInfo) == false) {
                let orgInfoArr = JSON.parse(userInfo);
                if (checkEmpty(orgInfoArr) == false) {
                    config.headers['Xlt-Id'] = orgInfoArr.accountId;
                    config.headers['Xlt-Token'] = orgInfoArr.token;
                }
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

service.interceptors.response.use(
    (response) => {
        const res = response.data;
        const responses = response
        // 二进制数据则直接返回
        if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
            return response;
        }
        if (res.code !== 0) {
            if (responses.config.url == '/student/calscore') {//如果是学分重新统计接口，抛出错误msg
                return Promise.reject(res)
            } else {
                Message({
                    message: res.msg,
                    type: 'error',
                    duration: 2 * 1000,
                });
                if (res.code === -100) {
                    store.dispatch('user/refreshOrgToken').then(() => { });
                }
                return Promise.reject(new Error(res.msg || '系统繁忙，请您稍后重试'));
            }
        } else {
            return res;
        }
    },
    (error) => {
        Message({
            message: error.msg || '网络请求失败',
            type: 'error',
            duration: 2 * 1000,
        });
    },
);


export default service;