import { getToken } from '@/utils/auth';
import axios from "axios";
import vm from "../main";
import { sort_ASCII, signStr } from "./sin";
const sha256 = require("js-sha256").sha256; //引入sha256库

var http = axios.create({
    baseURL: 'https://stu.x-lingtong.com/xytr-api',
    timeout: 300000
});

var userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
/* 请求拦截器 */
http.interceptors.request.use(
    config => {
        config.headers.timestamp = Math.floor(new Date().getTime() / 1000);
        let token = getToken(); //获取token
        config.headers["Authorization"] = "Bearer " + token;
        let JSESSIONID = localStorage.getItem('JSESSIONID') || ''
        config.headers.JSESSIONID = JSESSIONID
        // 接口没返回时显示loadin
        if (config.url != '/reslib/course/sync') {
        }
        if (config.loading === true) {
            vm.$loading.hide();
            vm.$loading.show();
        }
        //动态生成sign
        if (config.method === "get") {
            if (config.params) {
                basicParameters(config.params);
            }
            const data = JSON.stringify(sort_ASCII(config.params));
            const key = "UHgyffFWSwuLq5Cs";
            const originKey = `${data}${key}`;
            const sign = sha256(originKey);
            config.headers["sign"] = sign;
        }

        if (config.method === "post") {
            if (config.data) {
                basicParameters(config.data);
            }
            const data = JSON.stringify(sort_ASCII(config.data));
            const key = "UHgyffFWSwuLq5Cs";
            const originKey = `${data}${key}`;
            const sign = sha256(originKey);
            config.headers["sign"] = sign;
        }
        return config;
    },
    error => {
        //关闭loading
        return Promise.reject(error);
    }
);

/* 响应拦截器 */
http.interceptors.response.use(
    res => {
        //关闭loading
        if (res.data.code != 200) {
            Notify(res.data.msg);
        }
        return res;
    },
    error => {
        //关闭loading
        return Promise.reject(error);
    }
);

function get (url, data, api2) {
    return new Promise((resolve, reject) => {
        http.get(url, { params: data }, { api2: api2 }).then(response => {
            resolve(response.data);
            if (response.data.code != 200) {
                reject(response.data);
            } else {
                resolve(response.data);
            }
        },
            err => {
                reject(err);
            }
        ).catch(error => {
            reject(error);
        });
    });
}

function post (url, data) {
    return new Promise((resolve, reject) => {
        http.post(url, data).then(
            response => {
                if (response.data.code != 200) {
                    reject(response.data);
                } else {
                    resolve(response.data);
                }
            },
            err => {
                reject(err);
            }
        ).catch(error => {
            reject(error);
        });
    });
}

function basicParameters (config) {
    userInfo = JSON.parse(localStorage.getItem("orgInfo")) || {};
    if (!config.schoolId) {
        config.schoolId = userInfo.studentInfo
            ? userInfo.studentInfo.schoolId
            : '';
    }
}
export { http, get, post };
